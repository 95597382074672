import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReplayIcon from '@mui/icons-material/Replay';
import TodayIcon from '@mui/icons-material/Today';
import { setTimeHistory } from 'App/api/localStorage';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

const QuickDates = ({ handleClose, updateDates, labels = {}, quickSelectOptions, dates }) => {
  const handleClick = useCallback(
    dateRange => {
      updateDates(dateRange);
      setTimeHistory(dateRange);
      handleClose();
    },
    [updateDates, handleClose]
  );
  return (
    <Box sx={{ p: 2 }}>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '0.01em',
          textAlign: 'left',
          pb: 0.5,
        }}
        color="text.secondary"
      >
        {labels.quickDatesTitle || 'Quick dates'}
      </Typography>
      <List disablePadding>
        {quickSelectOptions.map((item, idx) => (
          <ListItem
            key={idx}
            disablePadding
            sx={{ backgroundColor: dates?.dateOptionIndex === idx ? 'surface.8' : 'inherit' }}
          >
            <ListItemButton sx={{ height: '32px', p: 1 }} onClick={() => handleClick(item.getDate())}>
              <ListItemIcon sx={{ minWidth: 24, pr: 1 }}>
                {idx === 0 ? <TodayIcon fontSize="small" /> : <ReplayIcon fontSize="small" />}
              </ListItemIcon>
              <ListItemText primary={item.label} primaryTypographyProps={{ variant: 'body2' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default QuickDates;
