import React from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

const SelectInput = ({
  id,
  name,
  error,
  control,
  intlLabelId,
  errorIntlId,
  defaultValue,
  rules,
  className,
  children,
  disabled,
  variant = 'standard',
  fullWidth = true,
  getHelperTextFromValue,
  sx,
  isMessageId = true,
  label,
}) => (
  <FormControl fullWidth={fullWidth} sx={sx} className={className} error={error} variant={variant}>
    {!isMessageId && label && <InputLabel htmlFor={id}>{label}</InputLabel>}
    {intlLabelId && (
      <InputLabel htmlFor={id}>
        <FormattedMessage id={intlLabelId} />
      </InputLabel>
    )}
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      error={error}
      render={({ field }) => (
        <>
          <Select disabled={disabled} id={id} {...field}>
            {children}
          </Select>
          {getHelperTextFromValue && <FormHelperText>{getHelperTextFromValue(field.value)}</FormHelperText>}
        </>
      )}
    />
    {error && (
      <FormHelperText>
        <FormattedMessage id={errorIntlId} />
      </FormHelperText>
    )}
  </FormControl>
);

export default SelectInput;
