import React, { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { object, date } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText, Stack } from '@mui/material';

import QuickDates from './components/QuickDates';
import StartingDate from './components/StartingDate';
import EndingDate from './components/EndingDate';
import RefreshEvery from './components/RefreshEvery';

const formSchema = (utils, labels) =>
  object().shape({
    fromDate: date().test({
      test(value, ctx) {
        const fromDate = utils.date(value);
        const toDate = utils.date(ctx.options.parent.toDate);
        if (
          (utils.isEqual(fromDate, toDate) && utils.getHours(toDate) < utils.getHours(fromDate)) ||
          utils.isBefore(toDate, fromDate)
        ) {
          return ctx.createError({ message: labels.errorIsBeforeOther || 'End date cannot be before start date' });
        }
        return true;
      },
    }),
    toDate: date().test({
      test(value, ctx) {
        const toDate = utils.date(value);
        const fromDate = utils.date(ctx.options.parent.fromDate);
        if (
          (utils.isEqual(toDate, fromDate) && utils.getHours(fromDate) > utils.getHours(toDate)) ||
          utils.isAfter(fromDate, toDate)
        ) {
          return ctx.createError({ message: labels.errorIsAfterOther || 'Start date cannot be after end date' });
        }
        return true;
      },
    }),
  });

const DateRangeForm = ({ handleClose, dates, updateDates, labels, defaultValues, utils }) => {
  const schema = useMemo(() => formSchema(utils, labels), [utils, labels]);
  const { handleSubmit, watch, control, getValues, formState } = useForm({
    defaultValues: {
      fromDate: dates ? dates.fromDate : defaultValues.fromDate,
      toDate: dates ? dates.toDate : defaultValues.toDate,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
    criteriaMode: 'firstError',
  });
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');
  const onSubmitCallback = useCallback(
    data => {
      const { fromDate, toDate } = data;
      updateDates({ fromDate: utils.date(fromDate), toDate: utils.date(toDate) });
      handleClose();
    },
    [updateDates, handleClose, utils]
  );

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', minWidth: '520px' }}>
        <StartingDate
          fromDate={fromDate}
          toDate={toDate}
          control={control}
          getValues={getValues}
          labels={labels}
          utils={utils}
        />
        <EndingDate
          fromDate={fromDate}
          toDate={toDate}
          control={control}
          getValues={getValues}
          labels={labels}
          utils={utils}
        />
      </Box>
      {formState.errors['fromDate']?.message && (
        <FormHelperText error>{formState.errors.fromDate.message}</FormHelperText>
      )}
      {formState.errors['toDate']?.message && <FormHelperText error>{formState.errors.toDate.message}</FormHelperText>}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Stack spacing={2} direction="row">
          <Button color="inherit" variant="contained" size="small" onClick={handleClose}>
            {labels.cancelButton || 'Cancel'}
          </Button>
          <Button color="primary" variant="contained" size="small" onClick={handleSubmit(onSubmitCallback)}>
            {labels.setDatesButton || 'Set Dates'}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

const DateRangePicker = ({
  handleClose,
  dates,
  updateDates,
  labels,
  quickSelectOptions,
  defaultValues,
  utils,
  enableAutoRefresh,
}) => (
  <Box sx={{ display: 'flex' }}>
    <Box sx={{ display: 'flex', width: '200px', backgroundColor: 'surface.2' }}>
      <QuickDates
        dates={dates}
        labels={labels}
        handleClose={handleClose}
        updateDates={updateDates}
        quickSelectOptions={quickSelectOptions}
      />
    </Box>
    <Box sx={{ backgroundColor: 'surface.1', p: 2 }}>
      <DateRangeForm
        utils={utils}
        dates={dates}
        labels={labels}
        handleClose={handleClose}
        updateDates={updateDates}
        defaultValues={defaultValues}
      />
      {enableAutoRefresh && <RefreshEvery handleClose={handleClose} />}
    </Box>
  </Box>
);

export default DateRangePicker;
