import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import UpdateIcon from '@mui/icons-material/Update';
import RefreshIcon from '@mui/icons-material/Refresh';
import ButtonGroup from '@mui/material/ButtonGroup';

import {
  DATE_FORMAT,
  getTimeUnitFromMilliseconds,
  convertMillisecondsToRefreshTimeValue,
} from '../../FilterBar/FancyDateSelect/utils/dateConfig';

const getRefreshIntervalTooltip = ({ dates, intl }) => {
  if (!dates) return;
  const fromDate = dates.fromDate.format(DATE_FORMAT);
  const toDate = Number.isFinite(dates?.dateOptionIndex)
    ? intl.formatMessage({ id: 'DateRangePicker-tooltip-selected-date-now', defaultMessage: 'Now' })
    : dates.toDate.format(DATE_FORMAT);
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mr: 1 }}>
        {intl.formatMessage(
          { id: 'DateRangePicker-tooltip-selected-date-from', defaultValue: 'From: {fromDate}' },
          { fromDate }
        )}
      </Box>
      <Box>
        {intl.formatMessage(
          { id: 'DateRangePicker-tooltip-selected-date-to', defaultValue: 'To: {toDate}' },
          { toDate }
        )}
      </Box>
    </Box>
  );
};

const DateRangePickerButtonGroup = ({ refreshInterval, dates, onRefresh, handleClick }) => {
  const intl = useIntl();
  return (
    <ButtonGroup size="large" variant="contained" sx={{ ml: 1 }}>
      {refreshInterval && (
        <Fade in={!!refreshInterval}>
          <Tooltip title={dates !== null ? getRefreshIntervalTooltip({ dates: dates, intl }) : ''}>
            <Button
              startIcon={<UpdateIcon />}
              onClick={handleClick}
              sx={{
                pl: 1,
                pr: 1,
                '& .MuiButton-startIcon': {
                  marginLeft: 0,
                  marginRight: 1,
                },
              }}
            >
              {convertMillisecondsToRefreshTimeValue({
                value: refreshInterval,
                timeUnit: getTimeUnitFromMilliseconds(refreshInterval),
              })}{' '}
              {Array.from(getTimeUnitFromMilliseconds(refreshInterval))[0].toUpperCase()}
            </Button>
          </Tooltip>
        </Fade>
      )}
      <Tooltip title={intl.formatMessage({ id: 'Button-label-refresh', defaultMessage: 'Refresh' })}>
        <Button
          id="refresh-button"
          onClick={onRefresh}
          startIcon={<RefreshIcon />}
          sx={{
            pl: 1.5,
            pr: 1.5,
            '& .MuiButton-startIcon': {
              marginLeft: 0,
              marginRight: 0,
            },
          }}
        />
      </Tooltip>
    </ButtonGroup>
  );
};

export default DateRangePickerButtonGroup;
