import { useEffect } from 'react';
import { useQueryParam, NumberParam } from 'use-query-params';

export const useAutoRefresh = (dates, refreshDates, enableAutoRefresh) => {
  const [refreshInterval, setRefreshInterval] = useQueryParam('refreshInterval', NumberParam);

  useEffect(() => {
    if (refreshInterval && enableAutoRefresh && dates) {
      const id = setInterval(() => refreshDates(dates, refreshInterval), refreshInterval);
      return () => clearInterval(id);
    }
  }, [refreshInterval, dates, refreshDates, enableAutoRefresh]);
  return [refreshInterval, setRefreshInterval];
};
