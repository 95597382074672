import React from 'react';
import Box from '@mui/material/Box';
import { Controller } from 'react-hook-form';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CustomDateInputField from './CustomDateInputField';
import CustomTimeInputField from './CustomTimeInputField';

const EndingDate = ({ toDate, fromDate, control, labels, utils }) => (
  <Box sx={{ p: 1 }}>
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink htmlFor="ending-date">
        {labels.endingDate || 'Ending Date'}
      </InputLabel>
      <Controller
        render={({ field }) => (
          <CustomDateInputField
            {...field}
            minDate={fromDate}
            size="small"
            id="ending-date"
            format="YYYY-MM-DD"
            value={toDate}
            onChange={momentTo => {
              if (momentTo && utils.isValid(momentTo)) {
                field.onChange(momentTo);
              }
            }}
          />
        )}
        name={'toDate'}
        control={control}
      />
    </FormControl>
    <Box sx={{ mt: 1 }}>
      <Controller
        render={({ field }) => (
          <DateCalendar
            {...field}
            minDate={fromDate}
            value={toDate}
            onChange={momentTo => {
              if (momentTo && utils.isValid(momentTo)) {
                field.onChange(momentTo);
              }
            }}
            sx={{ backgroundColor: 'surface.2', borderRadius: '5px', width: '277px', height: '325px' }}
          />
        )}
        name={'toDate'}
        control={control}
      />
    </Box>
    <FormControl variant="standard" fullWidth sx={{ mt: 1 }}>
      <InputLabel shrink htmlFor="ending-time">
        {labels.endingTime || 'Ending Time'}
      </InputLabel>
      <Controller
        render={({ field }) => (
          <CustomTimeInputField
            {...field}
            value={toDate}
            ampm={false}
            minTime={fromDate}
            size="small"
            id="ending-time"
            onChange={momentTo => {
              if (momentTo && utils.isValid(momentTo)) {
                field.onChange(momentTo);
              }
            }}
          />
        )}
        name={'toDate'}
        control={control}
      />
    </FormControl>
  </Box>
);

export default EndingDate;
