import React, { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { mixed, number, object } from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import StopIcon from '@mui/icons-material/Stop';
import PlayIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';

import { SelectInput, TextFieldInput } from 'sharedComponents/Form';
import { useDateContext } from '../../FilterBar/FancyDateSelect/utils/DateContext';
import {
  calculateRefreshIntervalValue,
  TIME_UNITS,
  convertMillisecondsToRefreshTimeValue,
  getTimeUnitFromMilliseconds,
} from '../../FilterBar/FancyDateSelect/utils/dateConfig';

const TIME_UNITS_OPTIONS = [
  { value: TIME_UNITS[0], intlId: 'FancyDateSelect-RefreshEvery-timeUnitOption-seconds' },
  { value: TIME_UNITS[1], intlId: 'FancyDateSelect-RefreshEvery-timeUnitOption-minutes' },
  { value: TIME_UNITS[2], intlId: 'FancyDateSelect-RefreshEvery-timeUnitOption-hours' },
];

const useRefreshEveryFormSchema = () => {
  const intl = useIntl();
  return object().shape({
    timeValue: number()
      .positive()
      .required(() =>
        intl.formatMessage({
          id: 'validation.form.field.required',
          defaultMessage: 'Field is required',
        })
      )
      .when(['timeUnit'], {
        is: TIME_UNITS[0],
        then: schema =>
          schema.min(5, ({ min }) =>
            intl.formatMessage(
              {
                id: 'validation.form.field.min.value',
                defaultMessage: 'Must be more then {min}.',
              },
              { min }
            )
          ),
        otherwise: schema =>
          schema.min(1, ({ min }) =>
            intl.formatMessage(
              {
                id: 'validation.form.field.min.value',
                defaultMessage: 'Must be more then {min}.',
              },
              { min }
            )
          ),
      }),
    timeUnit: mixed().required(() =>
      intl.formatMessage({
        id: 'validation.form.field.required',
        defaultMessage: 'Field is required',
      })
    ),
  });
};

const RefreshEvery = ({ handleClose }) => {
  const { refreshInterval, setRefreshInterval } = useDateContext();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      timeValue: refreshInterval
        ? convertMillisecondsToRefreshTimeValue({
            value: refreshInterval,
            timeUnit: getTimeUnitFromMilliseconds(refreshInterval),
          })
        : 60,
      timeUnit: refreshInterval ? getTimeUnitFromMilliseconds(refreshInterval) : TIME_UNITS_OPTIONS[0].value,
    },
    resolver: yupResolver(useRefreshEveryFormSchema()),
  });

  const onSubmit = useCallback(
    formData => {
      if (refreshInterval) {
        setRefreshInterval(undefined);
      } else {
        const { timeValue, timeUnit } = formData;
        setRefreshInterval(calculateRefreshIntervalValue({ value: timeValue, timeUnit }));
        handleClose();
      }
    },
    [setRefreshInterval, handleClose, refreshInterval]
  );

  return (
    <Box sx={theme => ({ marginLeft: theme.spacing(1) })}>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        <FormattedMessage id="FancyDateSelect-RefreshEvery-title" />
      </Typography>
      <Box>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2, alignItems: 'flex-end' }}
        >
          <TextFieldInput
            type="number"
            fullWidth={false}
            isMessageId={false}
            id="timeValueRefreshEvery"
            disabled={!!refreshInterval}
            errors={errors}
            registerProps={register('timeValue')}
          />
          <SelectInput
            control={control}
            id="timeUnit"
            name="timeUnit"
            isMessageId={false}
            fullWidth={false}
            error={!!errors['timeUnit']}
            disabled={!!refreshInterval}
          >
            {TIME_UNITS_OPTIONS.map(option => (
              <MenuItem key={option.value} value={option.value}>
                <FormattedMessage id={option.intlId} />
              </MenuItem>
            ))}
          </SelectInput>
          <Button
            size="small"
            type="submit"
            color="primary"
            variant="contained"
            startIcon={refreshInterval ? <StopIcon /> : <PlayIcon />}
          >
            {refreshInterval ? (
              <FormattedMessage id="FancyDateSelect-RefreshEvery-stop-button" />
            ) : (
              <FormattedMessage id="FancyDateSelect-RefreshEvery-start-button" />
            )}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default RefreshEvery;
