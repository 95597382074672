import React, { useCallback, useState, useContext, useMemo, useRef } from 'react';
import { MuiPickersAdapterContext } from '@mui/x-date-pickers';
import { darken, lighten } from '@mui/material/styles';
import moment from 'moment';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import DateIcon from '@mui/icons-material/CalendarToday';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import DateRangePicker from './DateRangePicker';
import DateRangePickerButtonGroup from './components/DateRangePickerButtonGroup';

const DateRangePickerPopover = ({
  value,
  onChange,
  formatDate,
  labels = {},
  quickSelectOptions,
  defaultValues,
  enableAutoRefresh,
  refreshInterval,
}) => {
  const { utils } = useContext(MuiPickersAdapterContext);
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);
  const onOpenDatepicker = useCallback(
    value => {
      setOpen(value);
    },
    [setOpen]
  );
  const id = open ? 'date-range-picker-popover' : undefined;
  const inputText = useMemo(
    () =>
      `${formatDate ? formatDate(value?.fromDate) : utils.format(value?.fromDate, 'keyboardDate')} - ${
        formatDate ? formatDate(value?.toDate) : utils.format(value?.toDate, 'keyboardDate')
      }`,
    [value, formatDate, utils]
  );

  const onRefresh = useCallback(() => {
    if (value?.dateOptionIndex) {
      //Option.1: Quick dates is selected
      const selectedDateOption = quickSelectOptions[value.dateOptionIndex].getDate();
      onChange({
        fromDate: selectedDateOption.fromDate,
        toDate: selectedDateOption.toDate,
        dateOptionIndex: selectedDateOption.dateOptionIndex,
      });
    } else {
      //Option.2: From and to dates are selected from date picker
      onChange({ fromDate: value.fromDate, toDate: moment() });
    }
  }, [value, onChange, quickSelectOptions]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <OutlinedInput
        id="date-input"
        sx={theme => ({
          ml: 1,
          width: '250px',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.default
              : darken(theme.palette.background.default, 0.15),
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? lighten(theme.palette.background.default, 0.2)
                : darken(theme.palette.background.default, 0.2),
          },
        })}
        onClick={() => onOpenDatepicker(true)}
        ref={anchorEl}
        readOnly={true}
        size="small"
        value={inputText}
        endAdornment={
          <InputAdornment position="end">
            <DateIcon fontSize="small" />
          </InputAdornment>
        }
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => onOpenDatepicker(false)}
        slotProps={{ paper: { sx: { borderRadius: '10px' } } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <DateRangePicker
          utils={utils}
          defaultValues={defaultValues}
          handleClose={() => onOpenDatepicker(false)}
          dates={value}
          updateDates={onChange}
          labels={labels}
          quickSelectOptions={quickSelectOptions}
          enableAutoRefresh={enableAutoRefresh}
        />
      </Popover>
      <DateRangePickerButtonGroup
        dates={value}
        onRefresh={onRefresh}
        refreshInterval={refreshInterval}
        handleClick={() => onOpenDatepicker(true)}
      />
    </Box>
  );
};

export default DateRangePickerPopover;
