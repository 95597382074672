import moment from 'moment';

export const DATE_FORMAT = 'YYYY.MM.DD @ HH:mm:ss';
export const TIME_UNITS = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'];

export const calculateRefreshIntervalValue = ({ value, timeUnit }) => {
  switch (timeUnit) {
    case TIME_UNITS[0]:
      return value * 1000;
    case TIME_UNITS[1]:
      return value * (60 * 1000);
    case TIME_UNITS[2]:
      return value * (60 * 60 * 1000);
    default:
      return value * (60 * 1000);
  }
};

export const convertMillisecondsToRefreshTimeValue = ({ value, timeUnit }) => {
  switch (timeUnit) {
    case TIME_UNITS[0]:
      return value / 1000;
    case TIME_UNITS[1]:
      return value / (60 * 1000);
    case TIME_UNITS[2]:
      return value / (60 * 60 * 1000);
    default:
      return value / (60 * 1000);
  }
};

export const getTimeUnitFromMilliseconds = milliseconds => {
  if (milliseconds % (60 * 60 * 1000) === 0) {
    return TIME_UNITS[2];
  }
  if (milliseconds % (60 * 1000) === 0) {
    return TIME_UNITS[1];
  }
  if (milliseconds % 1000 === 0) {
    return TIME_UNITS[0];
  }
  return TIME_UNITS[0];
};

export const getTimeUnitLabel = filterDates => {
  const minutesDiff = moment(filterDates.toDate).diff(moment(filterDates.fromDate), 'minutes');
  const hoursDiff = moment(filterDates.toDate).diff(moment(filterDates.fromDate), 'hours');
  const daysDiff = moment(filterDates.toDate).diff(moment(filterDates.fromDate), 'days');
  const monthsDiff = moment(filterDates.toDate).diff(moment(filterDates.fromDate), 'months');
  const yearsDiff = moment(filterDates.toDate).diff(moment(filterDates.fromDate), 'years');

  if (minutesDiff === 0) return { id: 'FancyDateSelect-button-label-now' };
  if (hoursDiff < 1) {
    return {
      id: 'FancyDateSelect-button-label-minutes',
      values: { minutes: minutesDiff },
    };
  }
  if (hoursDiff >= 1 && hoursDiff <= 24) {
    return {
      id: 'FancyDateSelect-button-label-hours',
      values: { hours: hoursDiff },
    };
  }
  if (daysDiff > 1 && daysDiff <= 90) {
    return {
      id: 'FancyDateSelect-button-label-days',
      values: { days: daysDiff },
    };
  }
  if (daysDiff > 90 && daysDiff < 365) {
    return {
      id: 'FancyDateSelect-button-label-months',
      values: { months: monthsDiff },
    };
  }
  if (daysDiff >= 365) {
    return {
      id: 'FancyDateSelect-button-label-years',
      values: { years: yearsDiff },
    };
  }
  return {
    id: 'FancyDateSelect-button-label-hours',
    values: { hours: hoursDiff },
  };
};
