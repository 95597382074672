import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';

export const useDateContextParams = (dateOptions, defaultDate) => {
  const [dateParams, setDateParams] = useQueryParams({
    fromDate: StringParam,
    toDate: StringParam,
    dateOptionIndex: NumberParam,
  });
  const [dateState, setDateState] = useState({});

  const setDates = useCallback(
    (fromDateInput, toDateInput, dateOptionIndexInput) => {
      if (
        fromDateInput.toISOString() !== dateParams.fromDate ||
        toDateInput.toISOString() !== dateParams.toDate ||
        dateParams.dateOptionIndex !== dateOptionIndexInput ||
        !dateState.fromDateState ||
        !dateState.toDateState
      ) {
        setDateParams(
          {
            fromDate: fromDateInput.toISOString(),
            toDate: toDateInput.toISOString(),
            dateOptionIndex: dateOptionIndexInput,
          },
          { replace: true }
        );
        setDateState({
          fromDateState: fromDateInput,
          toDateState: toDateInput,
          dateOptionIndexState: dateOptionIndexInput,
        });
      }
    },
    [setDateParams, dateParams, dateState]
  );

  useEffect(() => {
    const { fromDate, toDate, dateOptionIndex } = dateParams;
    if (
      (fromDate && dateState && dateState.fromDateState && fromDate !== dateState.fromDateState.toISOString()) ||
      (toDate && dateState && dateState.toDateState && toDate !== dateState.toDateState.toISOString()) ||
      (dateOptionIndex &&
        dateState &&
        (dateState.dateOptionIndexState === undefined ||
          (dateState.dateOptionIndexState && dateOptionIndex !== dateState.dateOptionIndexState)))
    ) {
      setDateState({
        fromDateState: fromDate ? moment(fromDate) : dateOptions[dateOptionIndex].getDate().fromDate,
        toDateState: toDate ? moment(toDate) : dateOptions[dateOptionIndex].getDate().toDate,
        dateOptionIndexState: dateOptionIndex,
      });
    }
  }, [dateParams, dateState, dateOptions]);

  useEffect(() => {
    const { fromDate, toDate, dateOptionIndex } = dateParams;
    if (dateOptionIndex && !fromDate && !toDate) {
      const date = dateOptions[dateOptionIndex] ? dateOptions[dateOptionIndex].getDate() : defaultDate;
      setDates(date.fromDate, date.toDate, date.dateOptionIndex);
    } else if (!fromDate || !toDate) {
      const date = !isNaN(defaultDate.dateOptionIndex)
        ? dateOptions[defaultDate.dateOptionIndex].getDate()
        : defaultDate;
      setDates(date.fromDate, date.toDate, date.dateOptionIndex);
    } else {
      const date = !isNaN(dateOptionIndex)
        ? dateOptions[dateOptionIndex].getDate()
        : { toDate: moment(toDate), fromDate: moment(fromDate) };
      setDates(date.fromDate, date.toDate, date.dateOptionIndex);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const updateDates = useCallback(
    params => {
      setDates(params.fromDate, params.toDate, params.dateOptionIndex);
    },
    [setDates]
  );
  const refreshDates = useCallback(
    (params, refreshInterval) => {
      if (Number.isFinite(params?.dateOptionIndex)) {
        let newDateParams = dateOptions[params.dateOptionIndex].getDate();
        setDates(
          // Use params.fromDate as it is the last updated datetime.
          params.fromDate.add(refreshInterval / 1000, 'seconds'),
          newDateParams.toDate,
          newDateParams.dateOptionIndex
        );
      } else {
        setDates(params.fromDate, params.toDate, null);
      }
    },
    [setDates, dateOptions]
  );
  const dates = useMemo(() => {
    if (dateState.fromDateState && dateState.toDateState) {
      return {
        fromDate: dateState.fromDateState,
        toDate: dateState.toDateState,
        dateOptionIndex: dateState.dateOptionIndexState,
      };
    }
    return null;
  }, [dateState]);

  return {
    dates,
    updateDates,
    refreshDates,
    options: dateOptions,
  };
};
